<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
        color="error"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          {{ error }}
        </div>
      </v-banner>
    </template>
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              Account Signup
              <span class="body-1">— Complete your profile</span>
            </div>
          </template>

          <v-form
            ref="form"
            v-model="valid"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="dealershipName"
                    label="Dealership"
                    :rules="[v => !!v || 'Dealership is required']"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="email"
                    label="Email Address"
                    class="purple-input"
                    :rules="[v => !!v || 'Email is required']"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="phone"
                    class="purple-input"
                    label="Phone Number"
                    :rules="[v => !!v || 'Phone is required']"
                    required
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="address_1"
                    label="Address"
                    class="purple-input"
                    :rules="[v => !!v || 'Address is required']"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="city"
                    label="City"
                    class="purple-input"
                    :rules="[v => !!v || 'City is required']"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="state"
                    label="State"
                    class="purple-input"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="zip"
                    class="purple-input"
                    label="Zip"
                    type="number"
                    :rules="[v => !!v || 'Zip code is required']"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="firstName"
                    label="Your First Name"
                    class="purple-input"
                    :rules="[v => !!v || 'First name is required']"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="lastName"
                    label="Your Last Name"
                    class="purple-input"
                    :rules="[v => !!v || 'Last name is required']"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="password"
                    label="Select a password"
                    class="purple-input"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[v => !!v || 'Password is required']"
                    required
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="verifyPassword"
                    label="Re-enter password"
                    class="purple-input"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[v => !!v || 'Password is required']"
                    required
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    :disabled="!valid"
                    @click="createAccount()"
                  >
                    Sign up!
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import AccountService from '@/services/AccountService.js'

  export default {
    name: 'Register',
    data: () => ({
      dealershipName: '',
      email: '',
      phone: '',
      address_1: '',
      city: '',
      state: '',
      zip: '',
      firstName: '',
      lastName: '',
      password: '',
      verifyPassword: '',
      showPassword: false,
      error: '',
      loading: false,
      loadingMessage: 'Creating Account',
      valid: true,
    }),
    created () {
      this.$vuetify.theme.dark = true
    },
    methods: {
      createAccount () {
        this.error = ''
        if (this.password !== this.verifyPassword) {
          this.error = 'Passwords do not match.'
          return
        }
        this.loading = true
        this.valid = false
        var data = {
          name: this.dealershipName,
          email: this.email,
          phone: this.phone,
          address_1: this.address_1,
          city: this.city,
          state: this.state,
          zip: this.zip,
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password,
        }
        AccountService.createNewAccount(data)
          .then(response => {
            this.loading = false
            this.$store.dispatch('logout')
            this.$store
              .dispatch('login', {
                email: this.email,
                password: this.password,
              })
              .then(() => {
                this.showLoading = false
                this.$router.push({ name: 'Home' })
              })
          })
          .catch(error => {
            this.loading = false
            this.valid = true
            var status = error.response.status.toString()
            if (status === '401') {
              this.error = 'That account already exists.'
            } else {
              this.error = error.response.statusText
            }
          })
      },
    },
  }
</script>
